import _ from 'lodash';

export interface BuildInfo {
  version: string;
  commit: string;
  isEnterprise: boolean;
  env: string;
}

export class User {
  email: string;
  gravatarUrl: string;
  hasEditPermissionInFolders: boolean;
  helpFlags1: number;
  id: number;
  isGrafanaAdmin: boolean;
  isSignedIn: boolean;
  locale: string;
  login: string;
  name: string;
  orgCount: number;
  orgId: number;
  orgName: string;
  orgRole: string;
  quota: any;
  timezone: string;

  constructor(options) {
    _.extend(this, {}, options);
  }
}

export class Settings {
  datasources: any;
  machines: any;
  models: any;
  pipelines: any;
  handlers: any;
  panels: any;
  appSubUrl: string;
  windowTitlePrefix: string;
  buildInfo: BuildInfo;
  newPanelTitle: string;
  bootData: any;
  externalUserMngLinkUrl: string;
  externalUserMngLinkName: string;
  externalUserMngInfo: string;
  mindsphere: boolean;
  allowOrgCreate: boolean;
  disableLoginForm: boolean;
  defaultDatasource: string;
  alertingEnabled: boolean;
  alertingErrorOrTimeout: string;
  alertingNoDataOrNullValues: string;
  authProxyEnabled: boolean;
  exploreEnabled: boolean;
  ldapEnabled: boolean;
  oauth: any;
  disableUserSignUp: boolean;
  loginHint: any;
  loginError: any;
  user: User;

  constructor(options) {
    const defaults = {
      datasources: {},
      machines: {},
      models: {},
      pipelines: {},
      handlers: {},
      windowTitlePrefix: 'APA - ',
      panels: {},
      newPanelTitle: 'Panel Title',
      playlist_timespan: '1m',
      unsaved_changes_warning: true,
      appSubUrl: '',
      buildInfo: {
        version: 'v1.0',
        commit: '1',
        env: 'production',
        isEnterprise: false,
      },
      user: {},
    };

    _.extend(this, defaults, options);
  }
}

const bootData = (window as any).grafanaBootData || { settings: {}, user: {} };
const options = bootData.settings;
options.user = bootData.user;
options.bootData = bootData;

const config = new Settings(options);
export default config;
