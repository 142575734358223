import _ from 'lodash';
import coreModule from '../core_module';
import config from 'app/core/config';
import 'vendor/flickity/flickity';
import 'vendor/parallax/parallax';
import $ from 'jquery';

export class LoginCtrl {
  /** @ngInject */
  constructor($scope, backendSrv, contextSrv, public $location) {
    $scope.oauth = config.oauth;
    $scope.oauthEnabled = _.keys(config.oauth).length > 0;
    $scope.ldapEnabled = config.ldapEnabled;
    $scope.authProxyEnabled = config.authProxyEnabled;
    $scope.progressIconFlag = 0;
    $scope.progressIconIndex = 0;

    const params = $location.search();

    $scope.loginModel = {
      user: '',
      password: '',
    };

    $scope.$on('$destroy', () => {
      $scope.progressIconIndex = 0;
      $('.progress-marker img').map((index, ele) => {
        $(ele).attr('src', '/public/img/black-' + index + '.svg');
      });
      clearInterval($scope.progressIconFlag);
    });

    $scope.verifyModel = {
      email: '',
      subscribeOnly: false,
    };

    $scope.signupModel = {
      email: '',
      password: '',
      orgName: '',
      username: '',
      code: '',
      confirmPassword: '',
    };

    $scope.passwordModel = {
      oldPassword: 'admin',
      newPassword: '',
      confirmNew: '',
    };

    $scope.result = {};
    $scope.working = false;

    $scope.disableLoginForm = config.disableLoginForm;
    $scope.disableUserSignUp = config.disableUserSignUp;
    $scope.loginHint = config.loginHint;

    $scope.loginMode = false;
    $scope.verifyMode = false;
    $scope.signupMode = false;
    $scope.passwordMode = false;

    $scope.verifyEmailEnabled = true;
    $scope.autoAssignOrg = false;

    backendSrv.get('/api/user/signup/options').then(options => {
      $scope.verifyEmailEnabled = options.verifyEmailEnabled;
      $scope.autoAssignOrg = options.autoAssignOrg;
    });

    document.onkeydown = (evt) => {
       if (evt.keyCode === 27) {
        $scope.hideLoginModal();
      }
    };

    $scope.showlogin = () => {
      $scope.showLoginModal();
      $(document).ready(() => {
        $('.slideEleLogin_js').slideDown(150);
        $('.slideEleVerify_js').slideUp(150);
        $('.slideEleSignup_js').slideUp(150);
        $('#username').focus();
      });

      if ($scope.loginMode || $scope.passwordMode) {
        $scope.autoFocus();
        return;
      }
      $scope.loginModel.user = params.email || '';
      $scope.loginModel.password = '';
      $scope.autoFocus();
      delete params.email;
      delete params.org;
      delete params.code;

      $scope.verifyMode = false;
      $scope.signupMode = false;
      $scope.passwordMode = false;
      $scope.loginMode = true;
    };
    $scope.autoFocus = () => {
      const userNameFocus = document.getElementById('username') as HTMLInputElement;
      const emailCoFocus = document.getElementById('email_co') as HTMLInputElement;
      userNameFocus.focus();
      emailCoFocus.focus();
      setTimeout(() => { userNameFocus.focus();
        emailCoFocus.focus();
      }, 250);
    };
    $scope.showverify = () => {

      $scope.showLoginModal();
      $(document).ready(() => {
        $('.slideEleLogin_js').slideUp(150);
        $('.slideEleVerify_js').slideDown(150);
        $('.slideEleSignup_js').slideUp(150);
      });


      if ($scope.verifyMode || $scope.passwordMode) {
        $scope.autoFocus();
        return;
      }

      if (!$scope.verifyEmailEnabled) {
        $scope.showsignup();
        return;
      }

      $scope.verifyModel.email = params.email;
      $scope.autoFocus();
      delete params.email;
      delete params.org;
      delete params.code;

      $scope.loginMode = false;
      $scope.signupMode = false;
      $scope.passwordMode = false;
      $scope.verifyMode = true;
    };

    $scope.showsignup = () => {
      if ($scope.signupMode || $scope.passwordMode) {
        return;
      }

      $scope.showLoginModal();

      $(document).ready(() => {
        $('.slideEleLogin_js').slideUp(150);
        $('.slideEleVerify_js').slideUp(150);
        $('.slideEleSignup_js').slideDown(150);
      });

      $scope.signupModel.email = params.email;
      $scope.signupModel.orgName = params.org;
      $scope.signupModel.code = params.code;
      $scope.signupModel.password = '';
      $scope.signupModel.confirmPassword = '';

      delete params.email;
      delete params.org;
      delete params.code;

      $scope.loginMode = false;
      $scope.verifyMode = false;
      $scope.passwordMode = false;
      $scope.signupMode = true;
    };

    $scope.login = () => {
      delete $scope.loginError;

      $scope.working = true;

      backendSrv
        .post('/login', $scope.loginModel)
        .then(result => {
          $scope.showLoginModal();
          $scope.result = result;
          $scope.working = false;

          if ($scope.loginModel.password !== 'admin' || $scope.ldapEnabled || $scope.authProxyEnabled) {
            $scope.hideLoginModal();
            $scope.toGrafana();
            return;
          } else {
            const fromView = document.querySelector('#login-view');
            const toView = document.querySelector('#change-password-view');

            $scope.working = true;
            fromView.className += ' add';
            setTimeout(() => {
              fromView.className += ' hidden';
            }, 250);
            setTimeout(() => {
              toView.classList.remove('hidden');
            }, 251);
            setTimeout(() => {
              toView.classList.remove('remove');
              $scope.working = false;
              $scope.loginMode = false;
              $scope.verifyMode = false;
              $scope.signupMode = false;
              $scope.passwordMode = true;
            }, 301);
          }
        })
        .catch(() => {
          $scope.working = false;
        });
    };

    $scope.changePassword = () => {
      $scope.passwordModel.oldPassword = 'admin';

      if ($scope.passwordModel.newPassword !== $scope.passwordModel.confirmNew) {
        $scope.appEvent('alert-warning', ['New passwords do not match', '']);
        return;
      }

      backendSrv.put('/api/user/password', $scope.passwordModel).then(() => {
        $scope.toGrafana();
      });
    };

    $scope.verify = () => {
      if (!$scope.verifyModel.email.match(/^\S+@\S+$/)) {
        $scope.appEvent('alert-warning', ['invalid email', '']);
        return;
      }

      $scope.working = true;
      if (params.invite) {
        $scope.verifyModel.subscribeOnly = false;
        backendSrv
          .post('/api/user/signup', $scope.verifyModel)
          .then(result => {
            $scope.working = false;
            if (result.status === 'SignUpCreated') {
              $scope.appEvent('alert-success', ['Email sent!', 'A verification email has been sent to your email']);
              $scope.showsignup(true);
            }
          })
          .catch(() => {
            $scope.working = false;
          });
      } else {
        $scope.verifyModel.subscribeOnly = true;
        backendSrv
          .post('/api/user/signup', $scope.verifyModel)
          .then(result => {
            $scope.working = false;
            if (result.status === 'SignUpCreated') {
              $scope.appEvent('alert-success', [
                'Verification Pending!',
                'A verification email will be sent to your email soon',
              ]);
            }
          })
          .catch(() => {
            $scope.working = false;
          });
      }
    };

    $scope.signUp = () => {
      if (!$scope.signupModel.email.match(/^\S+@\S+$/)) {
        $scope.appEvent('alert-warning', ['invalid email', '']);
        return;
      }
      if ($scope.signupModel.password !== $scope.signupModel.confirmPassword) {
        $scope.appEvent('alert-warning', ['Passwords do not match', '']);
        return;
      }
      $scope.signupModel.username = $scope.signupModel.email;

      $scope.working = true;
      backendSrv
        .post('/api/user/signup/step2', $scope.signupModel)
        .then(rsp => {
          $scope.working = false;
          if (rsp.code === 'redirect-to-select-org') {
            window.location.href = config.appSubUrl + '/profile/select-org?signup=1';
          } else {
            $scope.toGrafana();
          }
        })
        .catch(error => {
          $scope.working = false;
          if (error && error.data && error.data.error === 'UNIQUE constraint failed: org.name') {
            $scope.appEvent('alert-warning', [
              'Organization already exists!',
              'Please ask your organisation administrator to invite you!',
            ]);
          }
        });
    };

    $scope.skip = () => {
      $scope.toGrafana();
    };

    $scope.hideLoginModal = () => {
      $scope.loginModal = false;
      $('.main-container').removeClass('with-modal');
      $('#loginModal').removeClass('login-show-modal');
      $('.slideEleLogin_js').slideUp(100);
      $('.slideEleVerify_js').slideUp(100);
      $('.slideEleSignup_js').slideUp(100);
      $scope.autoFocus();
    };

    $scope.showLoginModal = () => {
      if (!$scope.loginModal) {
        $scope.loginModal = true;
        $('.main-container').addClass('with-modal');
        $('#loginModal').addClass('login-show-modal');
      }
    };

    $scope.toGrafana = () => {
      const params = $location.search();

      if (params.redirect && params.redirect[0] === '/') {
        window.location.href = config.appSubUrl + params.redirect;
      } else if ($scope.result.redirectUrl) {
        window.location.href = $scope.result.redirectUrl;
      } else {
        window.location.href = config.appSubUrl + '/';
      }
    };

    $scope.init();

    $(document).ready(() => {

      $scope.partner = false;
      if ($location.host().indexOf('amazonaws') > -1) {
        $scope.partner = true;
      }
      if ($location.host().indexOf('covacsis') > -1) {
        $scope.partner = true;
      }

      if ($scope.partner) {
        $scope.showlogin(false);
        $scope.hideLoginModal = () => {};
        $(".main-container").html("");
      } else {
        $('.slideEleLogin_js').slideUp(0);
        $('.slideEleVerify_js').slideUp(0);
        $('.slideEleSignup_js').slideUp(0);
        if (params.show === 'login' || window.location.host.indexOf('tvarit') > -1) {
          $scope.showlogin(false);
        } else if (params.show === 'verify') {
          $scope.showverify(false);
        } else if (params.show === 'register') {
          $scope.showsignup(false);
        }
      }
    });

    $(document).ready(() => {
      $('footer.footer').remove();
      // video auto play
      // const playPromise = $("#video-layer").get(0).play();
      // if (playPromise !== undefined) {
      //   playPromise.then(_ => {
      //   })
      //   .catch(error => {
      //     $("#video-layer").get(0).play();
      //   });
      // }
      $('.toggle').click(function(this, e) {
        e.preventDefault();

        const $this = $(this);

        if ($this.next().hasClass('show')) {
          $this.next().removeClass('show');
          $this.next().slideUp(350);
        } else {
          $this
            .parent()
            .parent()
            .find('li .inner')
            .removeClass('show');
          $this
            .parent()
            .parent()
            .find('li .inner')
            .slideUp(350);
          $this.next().toggleClass('show');
          $this.next().slideToggle(350);
        }
      });

      $(document).on('click', e => {
        if (
          $(e.target).closest('.login-modal-content').length === 0 &&
          $scope.loginModal &&
          $(e.target).closest('.home-nav-btn').length === 0 &&
          $(e.target).closest('.signup_Login').length === 0 &&
          $(e.target).closest('.signupsmall').length === 0
        ) {
          $scope.hideLoginModal();
        }
      });

      $('a[href^="#"]').on('click', function(this, e) {
        e.preventDefault();

        let target = this.hash;
        target = $(target);

        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: target.offset().top,
            },
            900,
            'swing',
            () => {
              window.location.hash = target;
            }
          );
      });

      $('.scroll-canvas').scroll(() => {
        const getscrolltop = $('.scroll-canvas').scrollTop();
        const getpositionofsignupsignin = $('.white-box-shadow').position().top;
        if (getscrolltop > (getpositionofsignupsignin-80)) {
          $('.signup_Login').show();
        } else {
          $('.signup_Login').hide();
        }
      });

      const progressSteps = $('.progress-step');
      $scope.progressIconFlag = setInterval(() => {
        if ($scope.progressIconIndex >= progressSteps.length) {
          $($('.progress-marker img')[$scope.progressIconIndex - 1]).attr(
            'src',
            '/public/img/black-' + ($scope.progressIconIndex - 1) + '.svg'
          );
          $scope.progressIconIndex = 1;
        } else {
          $scope.progressIconIndex++;
        }
        if ($scope.progressIconIndex - 2 >= 0) {
          $($('.progress-marker img')[$scope.progressIconIndex - 2]).attr(
            'src',
            '/public/img/black-' + ($scope.progressIconIndex - 2) + '.svg'
          );
        }
        $($('.progress-marker img')[$scope.progressIconIndex - 1]).attr(
          'src',
          '/public/img/pink-' + ($scope.progressIconIndex - 1) + '.svg'
        );
        $('span.progress-marker').removeClass('prog-big');
        $('span.progress-text-2').removeClass('prog-text-big');
        $(progressSteps[$scope.progressIconIndex - 1])
          .find('span.progress-marker')
          .addClass('prog-big');
        $(progressSteps[$scope.progressIconIndex - 1])
          .find('span.progress-text-2')
          .addClass('prog-text-big');
      }, 1500);

      $('.ul-sub-menu li').hover(function(this) {
        $('.ul-sub-menu li')
          .not($(this))
          .removeClass('active');
        $(this).toggleClass('active');
      });

      $('.main-menu .navbar-nav li.nav-item').click(function(this) {
        $('.main-menu .navbar-nav li.nav-item')
          .not($(this))
          .removeClass('active');
        $(this).toggleClass('active');
      });

      $('.main-menu .navbar-nav li.nav-item').hover(function(this) {
        $('.main-menu .navbar-nav li.nav-item')
          .not($(this))
          .removeClass('show');
        $(this).toggleClass('show');
      });

      $('.main-menu .menu-togglebar').click(() => {
        $('#navbarText').toggleClass('open');
      });

      $('.main-menu .menuclose').click(() => {
        $('#navbarText').toggleClass('open');
      });

      $('.filterBtn').click(() => {
        $('.filterDiv').toggleClass('open');
      });

      $('.closeFilter').click(() => {
        $('.filterDiv').toggleClass('open');
      });

      $('.closeCookie').click(() => {
        $('.cookieFooter').hide();
      });

      const logoIcn = $('.main-menu .navbar-brand');

      $(window).scroll(function(this) {
        if ($(this).scrollTop() > 50) {
          logoIcn.removeClass('logo-none');
          $('.logo-full').attr('src', 'images/Logo-white.png');
        } else {
          logoIcn.addClass('logo-none');
          $('.logo-full').attr('src', 'images/Logo.png');
        }
      });

      $('.automtv-carousel').flickity({
        // options
        cellAlign: 'center',
        wrapAround: true,
        contain: true,
        autoPlay: true,
      });

      if ('ontouchstart' in window) {
        document.documentElement.className = document.documentElement.className + ' touch';
      }
      if (!$('html').hasClass('touch')) {
        /* background fix */
        $('.parallax').css('background-attachment', 'fixed');
      }

      /* fix vertical when not overflow
        call fullscreenFix() if .fullscreen content changes */
      function fullscreenFix() {
        const h = $('body').height();
        // set .fullscreen height
        $('.content-b').each(function(this) {
          if ($(this).innerHeight() > h) {
            $(this)
              .closest('.fullscreen')
              .addClass('overflow');
          }
        });
      }

      $(window).resize(fullscreenFix);
      fullscreenFix();

      /* resize background images */
      function backgroundResize() {
        const windowH = $(window).height();
        $('.parallax-bg').each(function(this) {
          const path = $(this);
          // variables
          const contW = path.width();
          const contH = path.height();
          let imgW = path.attr('data-img-width');
          let imgH = path.attr('data-img-height');
          const ratio = imgW / imgH;
          // overflowing difference
          let diff = parseFloat(path.attr('data-diff'));
          diff = diff ? diff : 0;
          // remaining height to have fullscreen image only on parallax
          let remainingH = 0;
          if (path.hasClass('parallax') && !$('html').hasClass('touch')) {
            remainingH = windowH - contH;
          }
          // set img values depending on cont
          imgH = contH + remainingH + diff;
          imgW = imgH * ratio;
          // fix when too large
          if (contW > imgW) {
            imgW = contW;
            imgH = imgW / ratio;
          }
          //
          path.data('resized-imgW', imgW);
          path.data('resized-imgH', imgH);
          path.css('background-size', imgW + 'px ' + imgH + 'px');
        });
      }

      $(window).resize(backgroundResize);
      $(window).focus(backgroundResize);
      backgroundResize();

      /* set parallax background-position */
      function parallaxPosition() {
        const heightWindow = $(window).height();
        const topWindow = $(window).scrollTop();
        const bottomWindow = topWindow + heightWindow;
        const currentWindow = (topWindow + bottomWindow) / 2;
        $('.parallax').each(function(this) {
          const path = $(this);
          const height = path.height();
          let top = path.offset().top;
          let bottom = top + height;
          // only when in range
          if (bottomWindow > top && topWindow < bottom) {
            const imgH = path.data('resized-imgH');
            // min when image touch top of window
            const min = 0;
            // max when image touch bottom of window
            const max = -imgH + heightWindow;
            // overflow changes parallax
            const overflowH = height < heightWindow ? imgH - height : imgH - heightWindow;
            // fix height on overflow
            top = top - overflowH;
            bottom = bottom + overflowH;
            // value with linear interpolation
            const value = min + (max - min) * (currentWindow - top) / (bottom - top);
            // set background-position
            let orizontalPosition = path.attr('data-oriz-pos');
            orizontalPosition = orizontalPosition ? orizontalPosition : '50%';
            $(this).css('background-position', orizontalPosition + ' ' + value + 'px');
          }
        });
      }

      if (!$('html').hasClass('touch')) {
        $(window).resize(parallaxPosition);
        //$(window).focus(parallaxPosition);
        $(window).scroll(parallaxPosition);
        parallaxPosition();
      }
    });
  }
}

coreModule.controller('LoginCtrl', LoginCtrl);
